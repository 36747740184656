<template>
  <div
    class="site-wrapper"
    :class="{ 'site-sidebar--fold': sidebarFold }"
    v-loading.fullscreen.lock="loading"
    element-loading-text="拼命加载中">
    <template v-if="!loading">
      <main-navbar />
      <main-sidebar />
      <div class="site-content__wrapper" :style="{ 'min-height': documentClientHeight + 'px' }">
        <main-content v-if="!$store.state.common.contentIsNeedRefresh" />
      </div>
      <!-- <test></test> -->
      <main-footer />
    </template>
    <!-- <side-bar></side-bar> -->
  </div>
</template>

<script>
  import MainNavbar from './main-navbar'
  import MainSidebar from './main-sidebar'
  import MainContent from './main-content'
  import MainFooter from './main-footer'

  export default {
    provide () {
      return {
        // 刷新
        refresh () {
          this.$store.commit('common/updateContentIsNeedRefresh', true)
          this.$nextTick(() => {
            this.$store.commit('common/updateContentIsNeedRefresh', false)
          })
        }
      }
    },
    data () {
      return {
        loading: false
      }
    },
    components: {
      MainNavbar,
      MainSidebar,
      MainContent,
      MainFooter,
      // test
    },
    computed: {
      documentClientHeight: {
        get () { return this.$store.state.common.documentClientHeight },
        set (val) { this.$store.commit('common/updateDocumentClientHeight', val) }
      },
      sidebarFold: {
        get () { return this.$store.state.common.sidebarFold }
      },
      userId: {
        get () { return this.$store.state.user.id },
        set (val) { this.$store.commit('user/updateId', val) }
      },
      userName: {
        get () { return this.$store.state.user.name },
        set (val) { this.$store.commit('user/updateName', val) }
      },
      userPhone: {
        get () { return this.$store.state.user.phone },
        set (val) { this.$store.commit('user/updatePhone', val) },
        
      },
      adminCode: {
        get () { return this.$store.state.user.adminCode },
        set (val) { this.$store.commit('user/updateAdminCode', val) }
      },
      adminName: {
        get () { return this.$store.state.user.adminName },
        set (val) { this.$store.commit('user/updateAdminName', val) }
      },
      //新增省份 城市全局变量
      adminCity:{
        get () { return this.$store.state.user.adminCity },
        set (val) { this.$store.commit('user/updateAdminCity', val) }
      },
      adminProvince:{
        get () { return this.$store.state.user.adminProvince },
        set (val) { this.$store.commit('user/updateAdminProvince', val) }
      },
      lastLogin:{
        get() { return this.$store.state.user.lastLogin },
        set(val) { this.$store.commit('user/updateLastLogin', val)}
      }
    },
    created () {
      // this.getUserInfo()
    },
    mounted () {
      this.resetDocumentClientHeight()
      
    },
    methods: {
      // 重置窗口可视高度
      resetDocumentClientHeight () {
        this.documentClientHeight = document.documentElement['clientHeight']
        window.onresize = () => {
          this.documentClientHeight = document.documentElement['clientHeight']
        }
      },
      // 获取当前管理员信息
      getUserInfo () {
        this.$http({
          url: this.$http.adornUrl('/sys/user/info'),
          method: 'get',
          params: this.$http.adornParams()
        }).then(({data}) => {
          if(data.code === 401) {
            window.location.reload()
          }
          else if (data && data.code === 0) {
            console.log("111看看登录时返回的东西")
            console.log(data)
            this.loading = false
            this.userId = data.user.userId  // 用户id
            this.userName = data.user.username  // 用户名
            this.adminCode = data.user.areaCode  // 地区代号
            this.adminName = data.user.areaName // 地区名
            this.userPhone = data.user.mobile // 手机号
            this.lastLogin = data.user.lastlogin

            if(data.parent.cityName!=null && data.parent.provinceName!=null){
              this.adminCity = data.parent.cityName
              this.adminProvince = data.parent.provinceName
            }
          }
        })
      }
    }
  }
</script>
<style>
.el-tabs__item {
  font-size: 16px;
}
</style>
